export const getContactInfo = (type, contacts) => {
  if (typeof contacts !== 'object') {
    return null
  }

  const filteredType = contacts.filter(contact => {
    if (Object.prototype.hasOwnProperty.call(contacts, 'type')) {
      return false
    }
    return type === contact.type
  })

  if (filteredType) {
    return filteredType[0]
  }
}

export const getPhoneWithoutSpaces = (phoneNumber) => {
  if (typeof phoneNumber !== 'string') {
    return null
  }

  return phoneNumber.replace(/ /g, '')
}
