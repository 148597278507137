import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { FormattedMessage } from 'react-intl'
import { Box } from 'rebass/styled-components'
import Obfuscate from 'react-obfuscate'

import Layout from '../layouts'
import SEO from '../components/seo'
import Container from '../components/container'
import { getContactInfo, getPhoneWithoutSpaces } from '../utils/getContactInfo'

const ContactPage = (props) => {
  const { contact } = props.data.site.siteMetadata
  const phone = getContactInfo('phone', contact)
  const email = getContactInfo('email', contact)

  return (
    <Layout {...props} locale="en">
      <SEO title="Contact" />
      <Box py={4}>
        <Container maxWidth="780px">
          <h1>
            <FormattedMessage id="contact.title" defaultMessage="Contact" />
          </h1>

          <ul>
            <li>
              Adam Čeladín
            </li>
            <li>
              IČ: 01292471
            </li>
            <li>
              T: <Obfuscate tel={getPhoneWithoutSpaces(phone.value)}>{phone.value}</Obfuscate>
            </li>
            <li>
              E: <Obfuscate email={email.value} />
            </li>
          </ul>
        </Container>
      </Box>
    </Layout>
  )
}

ContactPage.propTypes = {
  data: PropTypes.object,
}

export default ContactPage

export const pageQuery = graphql`
  query ContactEnPageQuery {
    site {
      siteMetadata {
        contact {
          type
          value
          link
        }
      }
    }
  }
`
